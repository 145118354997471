var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":((_vm.$t('Username')) + " *"),"label-for":"login-username"}},[_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Username')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":((_vm.$t('Password')) + " *"),"label-for":"login-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"placeholder":_vm.$t('Password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('v-select',{attrs:{"options":_vm.languages,"clearable":false,"label":"text"},on:{"input":_vm.onChangeLanguage},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
var image = ref.image;
return [_c('span',{staticClass:"language-display"},[_c('img',{attrs:{"src":image,"alt":"image language","width":"18","height":"18"}}),_c('b',[_vm._v(_vm._s(text))])])]}},{key:"option",fn:function(option){return [_c('span',{staticClass:"language-display"},[_c('img',{attrs:{"src":option.image,"alt":"image language","width":"18","height":"18"}}),_c('b',[_vm._v(_vm._s(option.text))])])]}}],null,true),model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1),_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"options":_vm.timezones,"clearable":false,"label":"text"},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"options":_vm.currencys,"clearable":false,"label":"text"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
var image = ref.image;
return [_c('span',{staticClass:"language-display"},[_c('img',{attrs:{"src":image,"alt":"image language","width":"18","height":"18"}}),_c('b',[_vm._v(_vm._s(text))])])]}},{key:"option",fn:function(option){return [_c('span',{staticClass:"language-display"},[_c('img',{attrs:{"src":option.image,"alt":"image language","width":"18","height":"18"}}),_c('b',[_vm._v(_vm._s(option.text))])])]}}],null,true),model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1)],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('Login'))+" ")])],1)]}}])}),_c('div',{staticClass:"error-mesasges pt-1"},[_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"danger","show":_vm.dismissCountDown,"dismissible":""},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('Error'))+" ")]),_c('div',{staticClass:"alert-body"},[_c('div',{staticClass:"pb-2"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.errorMessage)))])])])])],1),_c('div',{staticClass:"divider"},[_c('hr')]),_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('div',{staticClass:"text-center"},[_vm._v(" AGENT MANAGEMENT 👋 ")]),_c('div',{staticClass:"text-center"},[_vm._v(" @ 2021 All Rights Reserved. ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }